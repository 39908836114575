.tacticsboard__backgrounds_container {
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100vh;
    z-index: 1500;
    overflow: auto;
    padding: 0px 16px 60px;
    background-color: #000;
}


.tacticsboard__backgrounds_title_wrapper {
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 60px;
    z-index: 1600;
}
.tacticsboard__backgrounds_title {
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 60px;
    z-index: 1600;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: flex-end;
    background-color: #000;
    margin-top: 30px;
    position: relative!important;
}
.continue_color{
    background-color: #6E6DD3 !important;
    color: #fff !important;
}
.tacticsboard__backgrounds_title > .ant-btn:hover{
    color: #fff !important;
}
.tacticsboard__backgrounds_title_label {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    @media(max-width: 768px){
        font-size: 16px;
    }
}
.tacticsboard__backgrounds_title_action {
    font-size: 13px !important;
}

.tacticsboard__backgrounds {
    width: 100% !important;
    margin-top: 27px !important;
    padding: 0px 40px;
}
.tacticsBoard__pitchType{
    padding: 0px 120px;
    @media(max-width: 992px){
        padding: 0px 45px;
    }
}

.tacticsboard__background {
    height: 208px;
    border-radius: 6px 6px 0px 0px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @media(max-width: 992px){
        height: 110px;
    }
    @media(max-width: 768px){
        height: 85px;
    }
}
.tacticsboard_field_wrapper {
    display: flex;
    margin-right: 16px;
}
.fieldType{
    width: 78px;
    height: 19px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}
.tacticsboard_field_wrapper span{
    cursor: pointer;
    margin-right: 16px;
}
.tacticsboard_building_lines input{
    width: 24px;
    height: 24px;
    position: absolute;
    margin-left: 16px;
}
.tacticsboard__background_viewer{
    width: 100%;
    height: 85%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border: 10px solid #5A5FE7;
    border-radius: 30px;
}
.start_drawing span{
    font-family: 'SF UI Text';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    cursor: pointer;
}
.start_drawing{
    margin-top: 10px;
    width: 100%;
    height: 64px;
    padding: 18px;
    background: #5A5FE7;
    border-radius: 10px;
    cursor: pointer;
}
.primary_border_marked {
    border: 5px solid #5A5FE7 !important;
    background-color: #0464b3 !important;
    &:hover {
        border: 5px solid #5A5FE7 !important;
        background-color: #0464b3 !important;
    }
}
.primary_border_marked {
    cursor: pointer !important;
    border: 5px solid #5A5FE7 !important;
}
.primary_border_mark:hover {
    cursor: pointer !important;
    border: 5px solid #5A5FE7 !important;
}
.background_pitch_container{
    flex-direction: column;
    width: 295px;
    height: 258px;
    border-radius: 8px 8px 6px 6px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-flex;
    border: 1px solid #EEECE9;
    box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
}
.tacticsBoard_start_drawing{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
}
.tacticsBoard_start_drawing button{
    width: 122px;
    padding: 6px 20px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    background: #5A5FE7 !important;
    border-radius: 6px;
}
.tacticsBoard_start_drawing button:hover{
    background: #2f3172 !important;;
}
.pitch_container_col{
    display: flex !important;
    justify-content: center;
}
@media only screen and (min-width:992px) and (max-width:1100px){
    .background_pitch_container{
        width: 210px;
        height: 210px;
    }
}

@media only screen and (max-width: 992px){

    .background_pitch_container{
        height: 145px;
    }
    .backgroundCustomStyle{
        padding: 10px !important;
    }
    .tacticsboard_alignment{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .tacticsboard__backgrounds_title {
        position: fixed !important;
        align-content: center;
        margin-top: 15px!important;
    }
    .tacticsboard__backgrounds{
        margin-top: 8px!important;
        margin-left: 0px !important;
    }
    .tacticsboard__background{
        margin: 0px 8px 0px 0px;
    }
    .tacticsboard__background_viewer{
        height: 78%;
        border: 5px solid #5A5FE7;
        border-radius: 15px;
    }
    .start_drawing {
        margin-top: 6px;
        height: 40px;
        padding: 5px;
    }
}

//Home Pitches
//.background_dark_p7 {
//    background-image: url(../../assets//images/HomePitches/dark_p7.jpg);
//}

// Basket Balls
.tacticsboard__background_basketBall_p1 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/basketBall_p1.jpg);
}

.tacticsboard__background_basketBall_p2 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/basketBall_p2.jpg);
}

.tacticsboard__background_line_basketBall_p1 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/basketBall_p1.jpg);
}

.tacticsboard__background_line_basketBall_p2 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/basketBall_p2.jpg);
}

// Futsal
.tacticsboard__background_futsal_p11 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/futsal_p11.jpg);
}

.tacticsboard__background_futsal_p12 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/futsal_p12.jpg);
}
.tacticsboard__background_futsal_p13 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/futsal_p13.jpg);
}

.tacticsboard__background_line_futsal_p11 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/futsal_p11.jpg);
}
.tacticsboard__background_line_futsal_p12 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/futsal_p12.jpg);
}
.tacticsboard__background_line_futsal_p13 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/futsal_p13.jpg);
}


//Default Pitches
.tacticsboard__background_default_p1 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/default_p1.png);
}
.tacticsboard__background_default_p2 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/default_p2.png);
}
.tacticsboard__background_default_p3 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/default_p3.png);
}
.tacticsboard__background_default_p4 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/default_p4.png);
}
.tacticsboard__background_default_p5 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/default_p5.png);
}
.tacticsboard__background_default_p6 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/default_p6.png);
}
.tacticsboard__background_default_p7 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/default_p7.png);
}
.tacticsboard__background_default_p8 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/default_p8.png);
}
.tacticsboard__background_default_p9 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/default_p9.png);
}

//Default Pitches Line
.tacticsboard__background_line_default_p1 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_default_p1.png);
}
.tacticsboard__background_line_default_p2 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_default_p2.png);
}
.tacticsboard__background_line_default_p3 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_default_p3.png);
}
.tacticsboard__background_line_default_p4 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_default_p4.png);
}
.tacticsboard__background_line_default_p5 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_default_p5.png);
}
.tacticsboard__background_line_default_p6 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_default_p6.png);
}
.tacticsboard__background_line_default_p7 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_default_p7.png);
}
.tacticsboard__background_line_default_p8 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_default_p8.png);
}
.tacticsboard__background_line_default_p9 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_default_p9.png);
}



//Light Pitches
.tacticsboard__background_light_p1 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/light_p1.jpg);
}
.tacticsboard__background_light_p2 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/light_p2.jpg);
}
.tacticsboard__background_light_p3 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/light_p3.jpg);
}
.tacticsboard__background_light_p4 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/light_p4.jpg);
}
.tacticsboard__background_light_p5 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/light_p5.jpg);
}
.tacticsboard__background_light_p6 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/light_p6.jpg);
}
.tacticsboard__background_light_p7 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/light_p7.jpg);
}
.tacticsboard__background_light_p8 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/light_p8.jpg);
}
.tacticsboard__background_light_p9 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/light_p9.jpg);
}

//Light Pitches
.tacticsboard__background_line_light_p1 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_light_p1.jpg);
}
.tacticsboard__background_line_light_p2 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_light_p2.jpg);
}
.tacticsboard__background_line_light_p3 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_light_p3.jpg);
}
.tacticsboard__background_line_light_p4 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_light_p4.jpg);
}
.tacticsboard__background_line_light_p5 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_light_p5.jpg);
}
.tacticsboard__background_line_light_p6 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_light_p6.jpg);
}
.tacticsboard__background_line_light_p7 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_light_p7.jpg);
}
.tacticsboard__background_line_light_p8 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_light_p8.jpg);
}
.tacticsboard__background_line_light_p9 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_light_p9.jpg);
}

//Dark Pitches
.tacticsboard__background_dark_p1 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/dark_p1.jpg);
}
.tacticsboard__background_dark_p2 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/dark_p2.jpg);
}
.tacticsboard__background_dark_p3 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/dark_p3.jpg);
}
.tacticsboard__background_dark_p4 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/dark_p4.jpg);
}
.tacticsboard__background_dark_p5 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/dark_p5.jpg);
}
.tacticsboard__background_dark_p6 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/dark_p6.jpg);
}
.tacticsboard__background_dark_p7 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/dark_p7.jpg);
}
.tacticsboard__background_dark_p8 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/dark_p8.jpg);
}
.tacticsboard__background_dark_p9 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/dark_p9.jpg);
}

//Dark Line Pitches
.tacticsboard__background_line_dark_p1 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_dark_p1.jpg);
}
.tacticsboard__background_line_dark_p2 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_dark_p2.jpg);
}
.tacticsboard__background_line_dark_p3 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_dark_p3.jpg);
}
.tacticsboard__background_line_dark_p4 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_dark_p4.jpg);
}
.tacticsboard__background_line_dark_p5 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_dark_p5.jpg);
}
.tacticsboard__background_line_dark_p6 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_dark_p6.jpg);
}
.tacticsboard__background_line_dark_p7 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_dark_p7.jpg);
}
.tacticsboard__background_line_dark_p8 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_dark_p8.jpg);
}
.tacticsboard__background_line_dark_p9 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_dark_p9.jpg);
}

//White Pitches
.tacticsboard__background_white_p1 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/white_p1.jpg);
}
.tacticsboard__background_white_p2 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/white_p2.jpg);
}
.tacticsboard__background_white_p3 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/white_p3.jpg);
}
.tacticsboard__background_white_p4 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/white_p4.jpg);
}
.tacticsboard__background_white_p5 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/white_p5.jpg);
}
.tacticsboard__background_white_p6 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/white_p6.jpg);
}
.tacticsboard__background_white_p7 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/white_p7.jpg);
}
.tacticsboard__background_white_p8 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/white_p8.jpg);
}
.tacticsboard__background_white_p9 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/white_p9.jpg);
}

//White Line Pitches
.tacticsboard__background_line_white_p1 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_white_p1.jpg);
}
.tacticsboard__background_line_white_p2 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_white_p2.jpg);
}
.tacticsboard__background_line_white_p3 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_white_p3.jpg);
}
.tacticsboard__background_line_white_p4 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_white_p4.jpg);
}
.tacticsboard__background_line_white_p5 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_white_p5.jpg);
}
.tacticsboard__background_line_white_p6 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_white_p6.jpg);
}
.tacticsboard__background_line_white_p7 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_white_p7.jpg);
}
.tacticsboard__background_line_white_p8 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_white_p8.jpg);
}
.tacticsboard__background_line_white_p9 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_white_p9.jpg);
}

//deepGreen Line Pitches
.tacticsboard__background_line_deepGreen_p1 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_deepGreen_p1.jpg);
}
.tacticsboard__background_line_deepGreen_p2 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_deepGreen_p2.jpg);
}
.tacticsboard__background_line_deepGreen_p3 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_deepGreen_p3.jpg);
}
.tacticsboard__background_line_deepGreen_p4 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_deepGreen_p4.jpg);
}
.tacticsboard__background_line_deepGreen_p5 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_deepGreen_p5.jpg);
}
.tacticsboard__background_line_deepGreen_p6 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_deepGreen_p6.jpg);
}
.tacticsboard__background_line_deepGreen_p7 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_deepGreen_p7.jpg);
}
.tacticsboard__background_line_deepGreen_p8 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_deepGreen_p8.jpg);
}
.tacticsboard__background_line_deepGreen_p9 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_deepGreen_p9.jpg);
}


//deepGreen Pitches
.tacticsboard__background_deepGreen_p1 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/deepGreen_p1.jpg);
}
.tacticsboard__background_deepGreen_p2 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/deepGreen_p2.jpg);
}
.tacticsboard__background_deepGreen_p3 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/deepGreen_p3.jpg);
}
.tacticsboard__background_deepGreen_p4 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/deepGreen_p4.jpg);
}
.tacticsboard__background_deepGreen_p5 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/deepGreen_p5.jpg);
}
.tacticsboard__background_deepGreen_p6 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/deepGreen_p6.jpg);
}
.tacticsboard__background_deepGreen_p7 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/deepGreen_p7.jpg);
}
.tacticsboard__background_deepGreen_p8 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/deepGreen_p8.jpg);
}
.tacticsboard__background_deepGreen_p9 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/deepGreen_p9.jpg);
}

.tacticsboard__modal_wrap {
  user-select: none;
  .ant-modal{
    .box {
      width: 40px;
      height: 40px;
      padding: 5px;
      border-radius: 10%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-color: transparent;
      //box-shadow: rgba(253, 253, 253, 0.2) 0px 0px 0px 1px inset, rgba(255, 255, 255, 0.9) 0px 0px 0px 1px;
    }
    .activeLayer {
      background-color: #5a5fe7 !important;
    }
    .box-container{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1px;
      background-color: #eeece9;
    }
    .rounded-corners{
      border-radius: 50% !important;
    }
    .drop-container{
      ul {
        z-index: 2;
        list-style: none;
        padding: 0;
        height: 100%;
        overflow-y: auto; /* Enable vertical scrolling */
        overflow-x: hidden; /* Disable horizontal scrolling */
        width: 100px;
        //[dir="rtl"] & {
        //  padding-left: 0; // Reset the left padding
        //  padding-right: 5px !important; // Set right padding for RTL
        //}
      }
      li{
        width: 70px;
      }
      /* width */
      ul::-webkit-scrollbar {
        width: 8px;
      }

      /* Track */
      ul::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px transparent;
        border-radius: 10px;
      }

      /* Handle */
      ul::-webkit-scrollbar-thumb {
        background: grey;
        border-radius: 10px;
        visibility:hidden;
      }
      ul:hover::-webkit-scrollbar-thumb {
        visibility:visible;
      }
      margin-top: 30px;
      width: 120px;
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;
      background: transparent;
      height: 90vh;

    }
    .layer-close-icon{
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
    }

  }}


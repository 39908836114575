
.dis-flex{
  display: flex;
}

.Equipments_scale{
  transform: scale(1.2);
  margin-left: -3px;
}

// Sprites

.Cone {
  background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/Cone-sprite.webp);
  background-repeat: no-repeat;
  display: block;
  cursor: pointer;

}
.Cone-Flat_DarkBlue {
  width: 48px;
  height: 50px;
  background-position: 0 0;
}

.Cone-Flat_Green {
  width: 48px;
  height: 50px;
  background-position: -48px 0;
}

.Cone-Flat_LightBlue {
  width: 48px;
  height: 50px;
  background-position: -96px 0;
}

.Cone-Flat_Red {
  width: 48px;
  height: 50px;
  background-position: -144px 0;
}

.Cone-Flat_White {
  width: 48px;
  height: 50px;
  background-position: -192px 0;
}

.Cone-Flat_Yellow {
  width: 48px;
  height: 50px;
  background-position: 0 -50px;
}

.Cone-Long_DarkBlue {
  width: 48px;
  height: 50px;
  background-position: -48px -50px;
}

.Cone-Long_Green {
  width: 48px;
  height: 50px;
  background-position: -96px -50px;
}

.Cone-Long_LightBlue {
  width: 48px;
  height: 50px;
  background-position: -144px -50px;
}

.Cone-Long_Red {
  width: 48px;
  height: 50px;
  background-position: -192px -50px;
}

.Cone-Long_White {
  width: 48px;
  height: 50px;
  background-position: 0 -100px;
}

.Cone-Long_Yellow {
  width: 48px;
  height: 50px;
  background-position: -48px -100px;
}

.Cone-T_DarkBlue {
  width: 48px;
  height: 50px;
  background-position: -96px -100px;
}

.Cone-T_Green {
  width: 48px;
  height: 50px;
  background-position: -144px -100px;
}

.Cone-T_LightBlue {
  width: 48px;
  height: 50px;
  background-position: -192px -100px;
}

.Cone-T_Red {
  width: 48px;
  height: 50px;
  background-position: 0 -150px;
}

.Cone-T_White {
  width: 48px;
  height: 50px;
  background-position: -48px -150px;
}

.Cone-T_Yellow {
  width: 48px;
  height: 50px;
  background-position: -96px -150px;
}

.Cone-TopL_DarkBlue {
  width: 48px;
  height: 50px;
  background-position: -144px -150px;
}

.Cone-TopL_Green {
  width: 48px;
  height: 50px;
  background-position: -192px -150px;
}

.Cone-TopL_LightBlue {
  width: 48px;
  height: 50px;
  background-position: -240px 0;
}

.Cone-TopL_Red {
  width: 48px;
  height: 50px;
  background-position: -240px -50px;
}

.Cone-TopL_White {
  width: 48px;
  height: 50px;
  background-position: -240px -100px;
}

.Cone-TopL_Yellow {
  width: 48px;
  height: 50px;
  background-position: -240px -150px;
}


// Stand


.Stand {
  background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/Stand.webp);
  background-repeat: no-repeat;
  display: block;
  cursor: pointer;
}

.Stand-DIv_0000 {
  width: 48px;
  height: 50px;
  background-position: -5px -5px;
}

.Stand-DIv_0001 {
  width: 48px;
  height: 50px;
  background-position: -63px -5px;
}

.Stand-DIv_0002 {
  width: 48px;
  height: 50px;
  background-position: -121px -5px;
}

.Stand-DIv_0003 {
  width: 48px;
  height: 50px;
  background-position: -179px -5px;
}

.Stand-DIv_0004 {
  width: 48px;
  height: 50px;
  background-position: -237px -5px;
}

.Stand-DIv_0005 {
  width: 48px;
  height: 50px;
  background-position: -295px -5px;
}

.Stand-DIv_0006 {
  width: 48px;
  height: 50px;
  background-position: -353px -5px;
}

.Stand-DIv_0007 {
  width: 48px;
  height: 50px;
  background-position: -5px -65px;
}

.Stand-DarkBlue_0000 {
  width: 48px;
  height: 50px;
  background-position: -63px -65px;
}

.Stand-DarkBlue_0001 {
  width: 48px;
  height: 50px;
  background-position: -121px -65px;
}

.Stand-DarkBlue_0002 {
  width: 48px;
  height: 50px;
  background-position: -179px -65px;
}

.Stand-DarkBlue_0003 {
  width: 48px;
  height: 50px;
  background-position: -237px -65px;
}

.Stand-DarkBlue_0004 {
  width: 48px;
  height: 50px;
  background-position: -295px -65px;
}

.Stand-DarkBlue_0005 {
  width: 48px;
  height: 50px;
  background-position: -353px -65px;
}

.Stand-DarkBlue_0006 {
  width: 48px;
  height: 50px;
  background-position: -5px -125px;
}

.Stand-DarkBlue_0007 {
  width: 48px;
  height: 50px;
  background-position: -63px -125px;
}

.Stand-Green_0000 {
  width: 48px;
  height: 50px;
  background-position: -121px -125px;
}

.Stand-Green_0001 {
  width: 48px;
  height: 50px;
  background-position: -179px -125px;
}

.Stand-Green_0002 {
  width: 48px;
  height: 50px;
  background-position: -237px -125px;
}

.Stand-Green_0003 {
  width: 48px;
  height: 50px;
  background-position: -295px -125px;
}

.Stand-Green_0004 {
  width: 48px;
  height: 50px;
  background-position: -353px -125px;
}

.Stand-Green_0005 {
  width: 48px;
  height: 50px;
  background-position: -5px -185px;
}

.Stand-Green_0006 {
  width: 48px;
  height: 50px;
  background-position: -63px -185px;
}

.Stand-Green_0007 {
  width: 48px;
  height: 50px;
  background-position: -121px -185px;
}

.Stand-LightBlue_0000 {
  width: 48px;
  height: 50px;
  background-position: -179px -185px;
}

.Stand-LightBlue_0001 {
  width: 48px;
  height: 50px;
  background-position: -237px -185px;
}

.Stand-LightBlue_0002 {
  width: 48px;
  height: 50px;
  background-position: -295px -185px;
}

.Stand-LightBlue_0003 {
  width: 48px;
  height: 50px;
  background-position: -353px -185px;
}

.Stand-LightBlue_0004 {
  width: 48px;
  height: 50px;
  background-position: -5px -245px;
}

.Stand-LightBlue_0005 {
  width: 48px;
  height: 50px;
  background-position: -63px -245px;
}

.Stand-LightBlue_0006 {
  width: 48px;
  height: 50px;
  background-position: -121px -245px;
}

.Stand-LightBlue_0007 {
  width: 48px;
  height: 50px;
  background-position: -179px -245px;
}

.Stand-Red_0000 {
  width: 48px;
  height: 50px;
  background-position: -237px -245px;
}

.Stand-Red_0001 {
  width: 48px;
  height: 50px;
  background-position: -295px -245px;
}

.Stand-Red_0002 {
  width: 48px;
  height: 50px;
  background-position: -353px -245px;
}

.Stand-Red_0003 {
  width: 48px;
  height: 50px;
  background-position: -5px -305px;
}

.Stand-Red_0004 {
  width: 48px;
  height: 50px;
  background-position: -63px -305px;
}

.Stand-Red_0005 {
  width: 48px;
  height: 50px;
  background-position: -121px -305px;
}

.Stand-Red_0006 {
  width: 48px;
  height: 50px;
  background-position: -179px -305px;
}

.Stand-Red_0007 {
  width: 48px;
  height: 50px;
  background-position: -237px -305px;
}

.Stand-White_0000 {
  width: 48px;
  height: 50px;
  background-position: -295px -305px;
}

.Stand-White_0001 {
  width: 48px;
  height: 50px;
  background-position: -353px -305px;
}

.Stand-White_0002 {
  width: 48px;
  height: 50px;
  background-position: -5px -365px;
}

.Stand-White_0003 {
  width: 48px;
  height: 50px;
  background-position: -63px -365px;
}

.Stand-White_0004 {
  width: 48px;
  height: 50px;
  background-position: -121px -365px;
}

.Stand-White_0005 {
  width: 48px;
  height: 50px;
  background-position: -179px -365px;
}

.Stand-White_0006 {
  width: 48px;
  height: 50px;
  background-position: -237px -365px;
}

.Stand-White_0007 {
  width: 48px;
  height: 50px;
  background-position: -295px -365px;
}

.Stand-Yellow_0000 {
  width: 48px;
  height: 50px;
  background-position: -353px -365px;
}

.Stand-Yellow_0001 {
  width: 48px;
  height: 50px;
  background-position: -411px -5px;
}

.Stand-Yellow_0002 {
  width: 48px;
  height: 50px;
  background-position: -411px -65px;
}

.Stand-Yellow_0003 {
  width: 48px;
  height: 50px;
  background-position: -411px -125px;
}

.Stand-Yellow_0004 {
  width: 48px;
  height: 50px;
  background-position: -411px -185px;
}

.Stand-Yellow_0005 {
  width: 48px;
  height: 50px;
  background-position: -411px -245px;
}

.Stand-Yellow_0006 {
  width: 48px;
  height: 50px;
  background-position: -411px -305px;
}

.Stand-Yellow_0007 {
  width: 48px;
  height: 50px;
  background-position: -411px -365px;
}


// AngleStand

.AngleStand {
  background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/AngleStand.webp);
  background-repeat: no-repeat;
  display: block;
  cursor: pointer;

}
.AngleStand-DarkBlue_0000 {
  width: 48px;
  height: 50px;
  background-position: -5px -5px;
}

.AngleStand-DarkBlue_0001 {
  width: 48px;
  height: 50px;
  background-position: -63px -5px;
}

.AngleStand-DarkBlue_0002 {
  width: 48px;
  height: 50px;
  background-position: -121px -5px;
}

.AngleStand-DarkBlue_0003 {
  width: 48px;
  height: 50px;
  background-position: -179px -5px;
}

.AngleStand-DarkBlue_0004 {
  width: 48px;
  height: 50px;
  background-position: -237px -5px;
}

.AngleStand-DarkBlue_0005 {
  width: 48px;
  height: 50px;
  background-position: -295px -5px;
}

.AngleStand-DarkBlue_0006 {
  width: 48px;
  height: 50px;
  background-position: -353px -5px;
}

.AngleStand-DarkBlue_0007 {
  width: 48px;
  height: 50px;
  background-position: -5px -65px;
}

.AngleStand-Green_0000 {
  width: 48px;
  height: 50px;
  background-position: -63px -65px;
}

.AngleStand-Green_0001 {
  width: 48px;
  height: 50px;
  background-position: -121px -65px;
}

.AngleStand-Green_0002 {
  width: 48px;
  height: 50px;
  background-position: -179px -65px;
}

.AngleStand-Green_0003 {
  width: 48px;
  height: 50px;
  background-position: -237px -65px;
}

.AngleStand-Green_0004 {
  width: 48px;
  height: 50px;
  background-position: -295px -65px;
}

.AngleStand-Green_0005 {
  width: 48px;
  height: 50px;
  background-position: -353px -65px;
}

.AngleStand-Green_0006 {
  width: 48px;
  height: 50px;
  background-position: -5px -125px;
}

.AngleStand-Green_0007 {
  width: 48px;
  height: 50px;
  background-position: -63px -125px;
}

.AngleStand-LightBlue_0000 {
  width: 48px;
  height: 50px;
  background-position: -121px -125px;
}

.AngleStand-LightBlue_0001 {
  width: 48px;
  height: 50px;
  background-position: -179px -125px;
}

.AngleStand-LightBlue_0002 {
  width: 48px;
  height: 50px;
  background-position: -237px -125px;
}

.AngleStand-LightBlue_0003 {
  width: 48px;
  height: 50px;
  background-position: -295px -125px;
}

.AngleStand-LightBlue_0004 {
  width: 48px;
  height: 50px;
  background-position: -353px -125px;
}

.AngleStand-LightBlue_0005 {
  width: 48px;
  height: 50px;
  background-position: -5px -185px;
}

.AngleStand-LightBlue_0006 {
  width: 48px;
  height: 50px;
  background-position: -63px -185px;
}

.AngleStand-LightBlue_0007 {
  width: 48px;
  height: 50px;
  background-position: -121px -185px;
}

.AngleStand-Red_0000 {
  width: 48px;
  height: 50px;
  background-position: -179px -185px;
}

.AngleStand-Red_0001 {
  width: 48px;
  height: 50px;
  background-position: -237px -185px;
}

.AngleStand-Red_0002 {
  width: 48px;
  height: 50px;
  background-position: -295px -185px;
}

.AngleStand-Red_0003 {
  width: 48px;
  height: 50px;
  background-position: -353px -185px;
}

.AngleStand-Red_0004 {
  width: 48px;
  height: 50px;
  background-position: -5px -245px;
}

.AngleStand-Red_0005 {
  width: 48px;
  height: 50px;
  background-position: -63px -245px;
}

.AngleStand-Red_0006 {
  width: 48px;
  height: 50px;
  background-position: -121px -245px;
}

.AngleStand-Red_0007 {
  width: 48px;
  height: 50px;
  background-position: -179px -245px;
}

.AngleStand-White_0000 {
  width: 48px;
  height: 50px;
  background-position: -237px -245px;
}

.AngleStand-White_0001 {
  width: 48px;
  height: 50px;
  background-position: -295px -245px;
}

.AngleStand-White_0002 {
  width: 48px;
  height: 50px;
  background-position: -353px -245px;
}

.AngleStand-White_0003 {
  width: 48px;
  height: 50px;
  background-position: -5px -305px;
}

.AngleStand-White_0004 {
  width: 48px;
  height: 50px;
  background-position: -63px -305px;
}

.AngleStand-White_0005 {
  width: 48px;
  height: 50px;
  background-position: -121px -305px;
}

.AngleStand-White_0006 {
  width: 48px;
  height: 50px;
  background-position: -179px -305px;
}

.AngleStand-White_0007 {
  width: 48px;
  height: 50px;
  background-position: -237px -305px;
}

.AngleStand-Yellow_0000 {
  width: 48px;
  height: 50px;
  background-position: -295px -305px;
}

.AngleStand-Yellow_0001 {
  width: 48px;
  height: 50px;
  background-position: -353px -305px;
}

.AngleStand-Yellow_0002 {
  width: 48px;
  height: 50px;
  background-position: -411px -5px;
}

.AngleStand-Yellow_0003 {
  width: 48px;
  height: 50px;
  background-position: -411px -65px;
}

.AngleStand-Yellow_0004 {
  width: 48px;
  height: 50px;
  background-position: -411px -125px;
}

.AngleStand-Yellow_0005 {
  width: 48px;
  height: 50px;
  background-position: -411px -185px;
}

.AngleStand-Yellow_0006 {
  width: 48px;
  height: 50px;
  background-position: -411px -245px;
}

.AngleStand-Yellow_0007 {
  width: 48px;
  height: 50px;
  background-position: -411px -305px;
}





// Dummy Stand

.DummyStand {
  background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/DummyStand.webp);
  background-repeat: no-repeat;
  display: block;
  cursor: pointer;

}
.DummyStand-DarkBlue_0000 {
  width: 48px;
  height: 50px;
  background-position: -5px -5px;
}

.DummyStand-DarkBlue_0001 {
  width: 48px;
  height: 50px;
  background-position: -63px -5px;
}

.DummyStand-DarkBlue_0002 {
  width: 48px;
  height: 50px;
  background-position: -121px -5px;
}

.DummyStand-DarkBlue_0003 {
  width: 48px;
  height: 50px;
  background-position: -179px -5px;
}

.DummyStand-DarkBlue_0004 {
  width: 48px;
  height: 50px;
  background-position: -237px -5px;
}

.DummyStand-DarkBlue_0005 {
  width: 48px;
  height: 50px;
  background-position: -295px -5px;
}

.DummyStand-DarkBlue_0006 {
  width: 48px;
  height: 50px;
  background-position: -353px -5px;
}

.DummyStand-DarkBlue_0007 {
  width: 48px;
  height: 50px;
  background-position: -5px -65px;
}

.DummyStand-Green_0000 {
  width: 48px;
  height: 50px;
  background-position: -63px -65px;
}

.DummyStand-Green_0001 {
  width: 48px;
  height: 50px;
  background-position: -121px -65px;
}

.DummyStand-Green_0002 {
  width: 48px;
  height: 50px;
  background-position: -179px -65px;
}

.DummyStand-Green_0003 {
  width: 48px;
  height: 50px;
  background-position: -237px -65px;
}

.DummyStand-Green_0004 {
  width: 48px;
  height: 50px;
  background-position: -295px -65px;
}

.DummyStand-Green_0005 {
  width: 48px;
  height: 50px;
  background-position: -353px -65px;
}

.DummyStand-Green_0006 {
  width: 48px;
  height: 50px;
  background-position: -5px -125px;
}

.DummyStand-Green_0007 {
  width: 48px;
  height: 50px;
  background-position: -63px -125px;
}

.DummyStand-LightBlue_0000 {
  width: 48px;
  height: 50px;
  background-position: -121px -125px;
}

.DummyStand-LightBlue_0001 {
  width: 48px;
  height: 50px;
  background-position: -179px -125px;
}

.DummyStand-LightBlue_0002 {
  width: 48px;
  height: 50px;
  background-position: -237px -125px;
}

.DummyStand-LightBlue_0003 {
  width: 48px;
  height: 50px;
  background-position: -295px -125px;
}

.DummyStand-LightBlue_0004 {
  width: 48px;
  height: 50px;
  background-position: -353px -125px;
}

.DummyStand-LightBlue_0005 {
  width: 48px;
  height: 50px;
  background-position: -5px -185px;
}

.DummyStand-LightBlue_0006 {
  width: 48px;
  height: 50px;
  background-position: -63px -185px;
}

.DummyStand-LightBlue_0007 {
  width: 48px;
  height: 50px;
  background-position: -121px -185px;
}

.DummyStand-Red_0000 {
  width: 48px;
  height: 50px;
  background-position: -179px -185px;
}

.DummyStand-Red_0001 {
  width: 48px;
  height: 50px;
  background-position: -237px -185px;
}

.DummyStand-Red_0002 {
  width: 48px;
  height: 50px;
  background-position: -295px -185px;
}

.DummyStand-Red_0003 {
  width: 48px;
  height: 50px;
  background-position: -353px -185px;
}

.DummyStand-Red_0004 {
  width: 48px;
  height: 50px;
  background-position: -5px -245px;
}

.DummyStand-Red_0005 {
  width: 48px;
  height: 50px;
  background-position: -63px -245px;
}

.DummyStand-Red_0006 {
  width: 48px;
  height: 50px;
  background-position: -121px -245px;
}

.DummyStand-Red_0007 {
  width: 48px;
  height: 50px;
  background-position: -179px -245px;
}

.DummyStand-White_0000 {
  width: 48px;
  height: 50px;
  background-position: -237px -245px;
}

.DummyStand-White_0001 {
  width: 48px;
  height: 50px;
  background-position: -295px -245px;
}

.DummyStand-White_0002 {
  width: 48px;
  height: 50px;
  background-position: -353px -245px;
}

.DummyStand-White_0003 {
  width: 48px;
  height: 50px;
  background-position: -5px -305px;
}

.DummyStand-White_0004 {
  width: 48px;
  height: 50px;
  background-position: -63px -305px;
}

.DummyStand-White_0005 {
  width: 48px;
  height: 50px;
  background-position: -121px -305px;
}

.DummyStand-White_0006 {
  width: 48px;
  height: 50px;
  background-position: -179px -305px;
}

.DummyStand-White_0007 {
  width: 48px;
  height: 50px;
  background-position: -237px -305px;
}

.DummyStand-Yellow_0000 {
  width: 48px;
  height: 50px;
  background-position: -295px -305px;
}

.DummyStand-Yellow_0001 {
  width: 48px;
  height: 50px;
  background-position: -353px -305px;
}

.DummyStand-Yellow_0002 {
  width: 48px;
  height: 50px;
  background-position: -411px -5px;
}

.DummyStand-Yellow_0003 {
  width: 48px;
  height: 50px;
  background-position: -411px -65px;
}

.DummyStand-Yellow_0004 {
  width: 48px;
  height: 50px;
  background-position: -411px -125px;
}

.DummyStand-Yellow_0005 {
  width: 48px;
  height: 50px;
  background-position: -411px -185px;
}

.DummyStand-Yellow_0006 {
  width: 48px;
  height: 50px;
  background-position: -411px -245px;
}

.DummyStand-Yellow_0007 {
  width: 48px;
  height: 50px;
  background-position: -411px -305px;
}

// Gate

.Gate {
  background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/SG-gate.webp);
  background-repeat: no-repeat;
  display: block;
  cursor: pointer;
}


.Gate-SG-DarkBlue-0000 {
  width: 48px;
  height: 50px;
  background-position: -5px -5px;
}

.Gate-SG-DarkBlue-0001 {
  width: 48px;
  height: 50px;
  background-position: -63px -5px;
}

.Gate-SG-DarkBlue-0002 {
  width: 48px;
  height: 50px;
  background-position: -121px -5px;
}

.Gate-SG-DarkBlue-0003 {
  width: 48px;
  height: 50px;
  background-position: -179px -5px;
}

.Gate-SG-DarkBlue-0004 {
  width: 48px;
  height: 50px;
  background-position: -237px -5px;
}

.Gate-SG-DarkBlue-0005 {
  width: 48px;
  height: 50px;
  background-position: -295px -5px;
}

.Gate-SG-DarkBlue-0006 {
  width: 48px;
  height: 50px;
  background-position: -353px -5px;
}

.Gate-SG-DarkBlue-0007 {
  width: 48px;
  height: 50px;
  background-position: -5px -65px;
}

.Gate-SG-Green-0000 {
  width: 48px;
  height: 50px;
  background-position: -63px -65px;
}

.Gate-SG-Green-0001 {
  width: 48px;
  height: 50px;
  background-position: -121px -65px;
}

.Gate-SG-Green-0002 {
  width: 48px;
  height: 50px;
  background-position: -179px -65px;
}

.Gate-SG-Green-0003 {
  width: 48px;
  height: 50px;
  background-position: -237px -65px;
}

.Gate-SG-Green-0004 {
  width: 48px;
  height: 50px;
  background-position: -295px -65px;
}

.Gate-SG-Green-0005 {
  width: 48px;
  height: 50px;
  background-position: -353px -65px;
}

.Gate-SG-Green-0006 {
  width: 48px;
  height: 50px;
  background-position: -5px -125px;
}

.Gate-SG-Green-0007 {
  width: 48px;
  height: 50px;
  background-position: -63px -125px;
}

.Gate-SG-LightBlue-0000 {
  width: 48px;
  height: 50px;
  background-position: -121px -125px;
}

.Gate-SG-LightBlue-0001 {
  width: 48px;
  height: 50px;
  background-position: -179px -125px;
}

.Gate-SG-LightBlue-0002 {
  width: 48px;
  height: 50px;
  background-position: -237px -125px;
}

.Gate-SG-LightBlue-0003 {
  width: 48px;
  height: 50px;
  background-position: -295px -125px;
}

.Gate-SG-LightBlue-0004 {
  width: 48px;
  height: 50px;
  background-position: -353px -125px;
}

.Gate-SG-LightBlue-0005 {
  width: 48px;
  height: 50px;
  background-position: -5px -185px;
}

.Gate-SG-LightBlue-0006 {
  width: 48px;
  height: 50px;
  background-position: -63px -185px;
}

.Gate-SG-LightBlue-0007 {
  width: 48px;
  height: 50px;
  background-position: -121px -185px;
}

.Gate-SG-Red-0000 {
  width: 48px;
  height: 50px;
  background-position: -179px -185px;
}

.Gate-SG-Red-0001 {
  width: 48px;
  height: 50px;
  background-position: -237px -185px;
}

.Gate-SG-Red-0002 {
  width: 48px;
  height: 50px;
  background-position: -295px -185px;
}

.Gate-SG-Red-0003 {
  width: 48px;
  height: 50px;
  background-position: -353px -185px;
}

.Gate-SG-Red-0004 {
  width: 48px;
  height: 50px;
  background-position: -5px -245px;
}

.Gate-SG-Red-0005 {
  width: 48px;
  height: 50px;
  background-position: -63px -245px;
}

.Gate-SG-Red-0006 {
  width: 48px;
  height: 50px;
  background-position: -121px -245px;
}

.Gate-SG-Red-0007 {
  width: 48px;
  height: 50px;
  background-position: -179px -245px;
}

.Gate-SG-White-0000 {
  width: 48px;
  height: 50px;
  background-position: -237px -245px;
}

.Gate-SG-White-0001 {
  width: 48px;
  height: 50px;
  background-position: -295px -245px;
}

.Gate-SG-White-0002 {
  width: 48px;
  height: 50px;
  background-position: -353px -245px;
}

.Gate-SG-White-0003 {
  width: 48px;
  height: 50px;
  background-position: -5px -305px;
}

.Gate-SG-White-0004 {
  width: 48px;
  height: 50px;
  background-position: -63px -305px;
}

.Gate-SG-White-0005 {
  width: 48px;
  height: 50px;
  background-position: -121px -305px;
}

.Gate-SG-White-0006 {
  width: 48px;
  height: 50px;
  background-position: -179px -305px;
}

.Gate-SG-White-0007 {
  width: 48px;
  height: 50px;
  background-position: -237px -305px;
}

.Gate-SG-Yellow-0000 {
  width: 48px;
  height: 50px;
  background-position: -295px -305px;
}

.Gate-SG-Yellow-0001 {
  width: 48px;
  height: 50px;
  background-position: -353px -305px;
}

.Gate-SG-Yellow-0002 {
  width: 48px;
  height: 50px;
  background-position: -411px -5px;
}

.Gate-SG-Yellow-0003 {
  width: 48px;
  height: 50px;
  background-position: -411px -65px;
}

.Gate-SG-Yellow-0004 {
  width: 48px;
  height: 50px;
  background-position: -411px -125px;
}

.Gate-SG-Yellow-0005 {
  width: 48px;
  height: 50px;
  background-position: -411px -185px;
}

.Gate-SG-Yellow-0006 {
  width: 48px;
  height: 50px;
  background-position: -411px -245px;
}

.Gate-SG-Yellow-0007 {
  width: 48px;
  height: 50px;
  background-position: -411px -305px;
}
.fixed-bottom{
  position: fixed;
  bottom: 5px;
  left: 50%;
  z-index: 2000;
  transform: translateX(-50%);
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  //width: 430px;
  width:80%;
  height: 52px;
  padding: 4px;
  border-radius: 8px 8px 0px 0px;
  overflow: hidden;
  gap: 4px
}
.bottom-tools{
  background: transparent !important;
  margin-top: 6px !important;
  align-items: center;
  justify-content: center;
  height: 24px !important;
}
.item-box{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 44px;
}
.play-panel{
  width: 100%;
}
.back-btn{
  width: 36px;
  height: 20px;
  padding: 2px 4px 2px 4px;
  border-radius: 8px;
  font-size: 12px;
  line-height: 16px;
  background: #F2F4F8;
  color: #726B85;;
}
.bck{
  display: flex !important;
  align-items: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  height: 45px;
}
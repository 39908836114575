.loading {
  position: fixed;
  top: 0;
  left: 0;
  // z-index: 70;
  z-index: 50000;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(165, 165, 165, 0.78);

  span {
    font-size: 25px;
    color: rgba(0, 0, 0, .7);
  }
}

.loading-animation {
  position: fixed;
  top: 0;
  left: 0;
  // z-index: 70;
  z-index: 50000;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;

  span {
    font-size: 25px;
    color: rgba(0, 0, 0, .7);
  }
}


.easy-tacticsboard-loader-label {
  font-size: 16px;
  font-weight: 600;
  margin-top: 16px;
  color: #000000;
}
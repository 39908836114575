.tacticsboard__animations_frames {
    display: flex;
    align-items: center;
    max-width: 100%;
    overflow: auto;
    padding-top: 4px;
}
/* width */
.tacticsboard__animations_frames::-webkit-scrollbar {
    height: 2px;
}

/* Track */
.tacticsboard__animations_frames::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 10px;
}

/* Handle */
.tacticsboard__animations_frames::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
    visibility:hidden;
}
.tacticsboard__animations_frames:hover::-webkit-scrollbar-thumb {
    visibility:visible;
}


.tacticsboard__animations_frames[dir="ltr"] {
    margin-left: 16px;
}
.tacticsboard__animations_frames[dir="rtl"] {
    margin-right: 16px;
}

.tacticsboard__animations_frames_add {
    font-size: 12px !important;
    margin-right: 5px;
}
.tacticsboard__animations_frames_add[dir="ltr"] {
    margin-left: 4px !important;
}
.tacticsboard__animations_frames_add[dir="rtl"] {
    margin-right: 4px !important;
}

.tacticsboard__animations_frame {
    flex-shrink: 0;
    min-width: 24px;
    height: 24px;
    font-size: 14px;
    margin: auto 1px;
    font-weight: 400;
    border-radius: 15px;
    display: flex;
    cursor: pointer;
    position: relative;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}

.tacticsboard__animations_frame_remove {
    top: -4px;
    right: -4px;
    width: 13px;
    height: 13px;
    display: flex;
    cursor: pointer;
    font-weight: 500;
    position: absolute;
    align-items: center;
    border-radius: 13px;
    justify-content: center;
    > span {
        font-size: 11px;
        margin-top: -3px;
    }
}
.speed-bar {
    height: 24px;
    padding: 0px 6px !important;
    display:flex !important;
    justify-content: space-evenly;
    background: #FFFFFF;
    border: 1px solid darkgray;
    border-radius:4px ;
    align-items: center;
    color: black;
    width: 60px !important;
    cursor: pointer;
}
.delay-container{
    margin-left: 4px;
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 30px;
    span{
        display: block;
        font-size: 10px;
        color: #FFFFFF;
    }
}
@media(min-width: 992px){
    .speed-bar:hover{
        padding: 0px !important;
    }
}

.disabled_speed-bar{
        pointer-events: none;
        opacity: 0.4;
}
.time-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.tacticsboard__header_pitches_container {
  opacity: 0.9;
  width: 340px;
  border-radius: 5px;
  padding: 16px 0px 8px 15px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

  @media(max-width: 992px){
    margin: -30px -15px 0px 50px !important;
    transform: scale(.9);
  }
}
.ant-dropdown-arrow {
  display: none !important;
}
.tacticsboard__header_pitches_container {
  .tacticsboard__background_leftSide{
    width: 150px;
    height: 100px;
    border-radius: 10px;
    border: 2px solid #000000;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-flex;
    margin: 0px 8px 3px 0px;
    @media(max-width: 992px){
      height: 88px;
    }
    @media(max-width: 768px){
      height: 70px;
    }
  }
}
.previous_drawing_container{
  display: flex;
  justify-content: space-evenly;
  padding: 10px !important;
}
.image_box_PD{
  padding: 5px;
  cursor: pointer;
}
.image_box_PD:hover{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
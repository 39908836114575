.app_drawer_avatar_header_container {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
}

.app_drawer_avatar_header_icon {
    font-size: 20px !important;
}

.app_drawer_avatar_header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.app_drawer_avatar_header[dir="ltr"] .app_drawer_avatar_header_avatar {
    margin-right: 12px !important;
}

.app_drawer_avatar_header[dir="rtl"] .app_drawer_avatar_header_avatar {
    margin-left: 12px !important;
}

.app_drawer_avatar_header_typography.with-avatar[dir="ltr"] {
    margin-left: 8px !important;
}

.app_drawer_avatar_header_typography.with-avatar[dir="rtl"] {
    margin-right: 8px !important;
}

.app_drawer_avatar_header_title {
    cursor: pointer;
    font-size: 14px;
    line-height: 1.0;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: white !important;
}

.app_drawer_avatar_header_title:hover {
    text-decoration: underline !important;
}

.app_drawer_avatar_header_subtitle {
    display: flex;
    font-size: 13px;
    line-height: 1.0;
    align-items: center;
    font-weight: lighter;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: white !important;

    >b {
        font-weight: 700;
    }

    >b[dir="rtl"] {
        margin-left: 4px;
    }

    >b[dir="ltr"] {
        margin-right: 4px;
    }
}
.tacticsboard__modal_wrap {
    .shirt_no_left {
        display: flex;
        flex-direction: row-reverse;
        padding-top: 3px;
    }
    .prim{
        color: #ffffff !important;
        border-color: #0464b3 !important;
        background-color: #0464b3 !important;
    }

    .shirt_no_right {
        display: flex;
        flex-direction: row;
        padding-top: 3px;
    }

    .add_custom_img {
        color: white;
        display: flex;
        justify-content: end;
        margin-top: -37px;
        margin-right: 10px;
        font-size: 22px;
        font-weight: bold;
        padding: 2px;
        cursor: pointer;
    }

    .borderTitle {
        font-weight: bold;
        font-size: 14px;
        color: #fff;
        display: flex;
        justify-content: center;
    }

    .borderStyleWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5px 0px;

        label {
            width: 40%;
        }

        input {
            visibility: hidden;
        }

        .dashedLine {
            color: #fff;
            background-color: #666666;
            height: 35px;
            padding: 8px;
            cursor: pointer;
        }

        .dottedStyle {
            background-color: #6E6DD3 !important;
        }

        .simpleLine {
            color: #fff;
            background-color: #666666;
            height: 35px;
            padding: 8px;
            cursor: pointer;
        }

        .dashedLine hr {
            border: none;
            border-top: 6px dashed #fff;
            width: 98%;
            margin-top: 7px;
        }

        .simpleLine hr {
            border: none;
            border-top: 6px solid #fff;
            background-color: #fff;
            width: 98%;
            margin-top: 7px;
        }
    }

    .background__gray {
        color: #000000;
        background-color: #424345 !important;
    }

    .tacticsboard__tools_container {
        border-radius: 0px !important;
        padding: 0px 0px 1px;
        box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    }

    .ant-modal {
        max-width: calc(100vw - 0px) !important;
    }

    .tacticsboard__tools_header {
        width: 100%;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .tacticsboard__tools__tabs {
        margin-top: 0px !important;

        .ant-tabs-nav-wrap {
            border-bottom: none !important;
        }
    }

    .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
    .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
        justify-content: space-evenly;
    }

    .ant-collapse-item>.ant-collapse-header>div {
        display: none;
    }

    .ant-collapse-header {
        margin-left: 10px;
    }

    .ant-collapse>.ant-collapse-item>.ant-collapse-header {
        color: rgb(255, 255, 255) !important;
        line-height: 2.5 !important;
    }

    .ant-collapse-content {
        background-color: #424345 !important;
        padding: 5px 5px !important;
    }
    .image_dim{
        width: 2.5rem !important;
        height: 2.5rem !important;
        object-fit: revert !important;
    }
    .image_dim_equipment{
        width: auto !important;
        height: 2rem !important;
        object-fit: revert !important;
    }
    .tacticsboard__draw_img {
        width: 2.5rem !important;
        height: 2.5rem !important;
    }

    .svg__number_icon {
        >img {
            height: 2.6rem !important;
            border-radius: 28px;
            box-shadow: 3px 6px 5px 2px rgba(0, 0, 0, 0.25), inset 2px 2px 3px rgba(0, 0, 0, 0.25) !important;
        }
    }

    .player-custom-image {
        >img {
            cursor: pointer;
            object-fit: contain;
            border-radius: 28px;
            box-shadow: 3px 6px 5px 2px rgba(0, 0, 0, 0.25), inset 2px 2px 3px rgba(0, 0, 0, 0.25) !important;
        }
    }
    .player-custom-image {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .scale_props{
        transform: scale(0.9);
    }
    .player_images_alignment{
        margin-left: -3px !important;
        margin-right: -10px !important;
    }
    .custom_player_nameTag{
        background: rgba(0, 0, 0, 0.75);
        border-radius: 10px;
        padding: 0px 5px;
        color: #fff;
        cursor: pointer;
        margin: 0px;
        text-align: center;
    }
    .mt-3{
        margin-top: 3px;
    }
    .player-element {
        overflow: unset !important;

        >img {
            box-shadow: 3px 6px 5px 2px rgba(0, 0, 0, 0.25), inset 2px 2px 3px rgba(0, 0, 0, 0.25) !important;
        }
    }
    .player-element-shirt {
        overflow: unset !important;

        >img {
            box-shadow: 0px 0px 0px 0px !important;
        }
    }
    .player-element-triangle{
        >img {
            box-shadow: 0px 0px !important;
        }
    }

    .svg__number_icon-small {
        >img {
            height: 26px !important;
            width: 26px !important;
            border-radius: 28px;
        }
    }


    .svg__number_icon-medium {
        >img {
            height: 32px !important;
            width: 32px !important;
            border-radius: 28px;
        }
    }

    .svg__number_icon-large {
        >img {
            height: 42px !important;
            width: 42px !important;
            border-radius: 28px;
        }
    }
    .tacticsBoard_tools_menu_container{
        width: 100%;
        background: #393836;
        display: flex;
        flex-direction: row;
    }
    .tacticsBoard_tools_menu_container {
        .MuiSvgIcon-root{
            font-size: 1.5rem !important;
        }
    }
    .tools_icon_container{
        z-index: 9999;
        display: flex;
        flex-direction: column;
        width: 80px;
        border-right: 1px solid #EEECE9;
        background: #ffffff;
        box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.1);
    }
    .tools_element{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 55px;
        border-width: 0px 1px 1px 0px;
        border-style: solid;
        border-color: #EEECE9;
    }
    .selectedPanel{
        .tools_title{
            color: #5A5FE7 !important;
        }
        background: #F5F6F7;
    }
    .selectedTitle{
        color: #FFFFFF !important;
        font-size: 12px !important;
        font-weight: bold !important;
    }
    .tools_title{
        cursor: default;
        color: #0a0a0a;
        font-family: "Rubik", Arial, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 20px;
    }
    .tools_element:hover{
        background: #F5F6F7;
        border-width: 0px 1px 1px 0px;
        border-style: solid;
        border-color: #EEECE9;
    }
    .tools_element button{
        background: transparent;
    }
    .back_to_player button{
        cursor: pointer;
        background: transparent;
        color:#FFFFFF;
    }
    .back_to_player span{
        cursor: pointer;
    }
    .tacticsBoard_mainTool_icon{
        height: 18px !important;
        width: 18px !important;
    }
    .tacticsBoard_tools_menu_expandable{
        height: 100%;
        width: 136px;
        left: 82px;
        position: absolute;
        padding: 19px 12px;
        background: rgba(0, 0, 0, 0.8);
        transform: translateX(-102%);
        transition: transform 0.3s ease-in-out;
    }
    .open_expandable{
        z-index: 1000;
        height: 100%;
        width: 136px;
        left: 82px;
        position: absolute;
        transform: translateX(0%);
        padding: 19px 12px;
        transition: transform 0.3s ease-in-out;
        background: rgba(0, 0, 0, 0.8);
        box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.1);
    }
    .back_to_player{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        cursor: pointer;
        background: #5a5fe7;
    }
    .sub_menu_container{
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: black;
        padding: 10px;
    }
    .tools_menu_drawer{
        transition: none 0.3s ease-in-out;
    }

    [ant-click-animating-without-extra-node='true']::after{display:none;}
    .expandable_sizes button{
        background: transparent !important;
        height: 25px;
        width: 25px;
    }
    .expandable_buttons{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 6px 20px;
        cursor: pointer;
        gap: 8px;
        height: 32px;;
        background: #FFFFFF;
        border-radius: 6px;
        margin-bottom: 15px;
    }
    .add_playerButton{
        background: #5A5FE7;
        color: #FFFFFF;
    }

    .expandable_bottom_container{
        flex: 0 0 100px;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        .expandable_sizes{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;
        }
    }
    .expandable_bottom_container span{
        user-select: none;
        font-family: "Rubik", Arial, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
    }
    .selected_color{
        height: 17px;
        width: 17px;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        border-radius: 10px;
        cursor: pointer;
    }
    .tacticsboard__formation_container{
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100vh - 280px);
    }
    .tacticsboard__formation_f3{
        margin-top: 5px;
        position: relative;
    }
    .tacticsboard__formation_f3:hover{
        .tacticsboard__formation_hoverd{
            display: flex;
        }
    }
    .tacticsboard__formation_hoverd{
        display: none;
        position: absolute;
        left: 0px;
        top: -3px;
        opacity: 0.9;
        height: 120px;
        width: 116px;
        background: #5A5FE7;
        border: 1px solid #5A5FE7;
        border-radius: 6px;
    }
}
.line-btn{
    width: 52px !important; ;
    height: 52px !important;
    border-radius: 10px !important;

}
.logo-container {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-bottom: 40px;
}

.tacticsboard__tools_dragger_icon {
    font-size: 16px !important;
}

.tacticsboard__tools_icon {
    font-size: 22px !important;
    margin-bottom: -12px !important;
}

#tacticsboard__tools {
    z-index: 9;
    width: 100% !important;
}

//
//#tacticsboard__tools_dragger_one {
//    z-index: 10;
//    cursor: move;
//}
//#tacticsboard__tools_dragger_two {
//    z-index: 10;
//    cursor: move;
//}
// Groups
.tacticsboard__feature_container {
    width: 100%;
    margin-top: 0px;

    .ant-radio-wrapper {
        color: white !important;
    }
}

.tacticsboard__feature_container_height {
    max-height: calc(100vh - 170px);
    overflow-y: auto;
    overflow-x: hidden;
}

/* width */
.tacticsboard__feature_container_height::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.tacticsboard__feature_container_height::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 10px;
}

/* Handle */
.tacticsboard__feature_container_height::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
    visibility: hidden;
}

.tacticsboard__feature_container_height:hover::-webkit-scrollbar-thumb {
    visibility: visible;
}
/* width */
.tacticsboard__formation_container::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.tacticsboard__formation_container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 10px;
}
.btn-wrp{
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.frm-btn{
    background: #FFFFFF !important;
    cursor: pointer;
}

/* Handle */
.tacticsboard__formation_container::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
    visibility: hidden;
}

.tacticsboard__formation_container:hover::-webkit-scrollbar-thumb {
    visibility: visible;
}

.tacticsboard__feature_icon {
    display: flex;
    height: 52px;
    width: 52px;
    cursor: pointer;
    overflow: hidden;
    padding: 4px;
    align-items: center;
    justify-content: center;
    user-select: none;
    background: #EEECE9;
    border-radius: 10px;
}

.width_100 {
    width: 100% !important;
}

.icon_ml {
    margin-left: 10px;
}

.draw_btn_size {
    width: 2.5rem !important;
    height: 2.5rem !important;
    padding: 0px !important;
    background: transparent !important;
}

.tacticsboard__feature_icon_container {
    display: flex !important;
    align-items: center !important;
    justify-content: center;
}

.tactictics__board_drawer {
    .borderTitle {
        font-weight: bold;
        font-size: 14px;
        color: #fff;
        display: flex;
        justify-content: center;
    }

    .borderStyleWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5px 0px;

        label {
            width: 40%;
        }

        input {
            visibility: hidden;
        }

        .dashedLine {
            color: #fff;
            background-color: #666666;
            height: 35px;
            padding: 8px;
            cursor: pointer;
        }

        .dottedStyle {
            background-color: #6E6DD3 !important;
        }

        .simpleLine {
            color: #fff;
            background-color: #666666;
            height: 35px;
            padding: 8px;
            cursor: pointer;
        }

        .dashedLine hr {
            border: none;
            border-top: 6px dashed #fff;
            width: 98%;
            margin-top: 7px;
        }

        .simpleLine hr {
            border: none;
            border-top: 6px solid #fff;
            background-color: #fff;
            width: 98%;
            margin-top: 7px;
        }
    }

    .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
    .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
        justify-content: space-evenly;
    }

    .ant-collapse-item>.ant-collapse-header>div {
        display: none;
    }

    .ant-collapse-header {
        margin-left: 10px;
    }

    .ant-collapse>.ant-collapse-item>.ant-collapse-header {
        color: rgb(255, 255, 255) !important;
        line-height: 2.5 !important;
    }

    .ant-collapse-content {
        background-color: #424345 !important;
        padding: 5px 5px !important;
    }
}

.tools_container_overeflow {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

/* width */
.tools_container_overeflow::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.tools_container_overeflow::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 10px;
}

/* Handle */
.tools_container_overeflow::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
    visibility: hidden;
}

.tools_container_overeflow:hover::-webkit-scrollbar-thumb {
    visibility: visible;
}

.justifyContentEnd {
    justify-content: end;
}

.collapse_panel {
    background-color: #666666;
    font-size: 13px;
    font-weight: bold;
    box-shadow: rgba(139, 139, 139, 0.3) 0px 0px 0px 3px;
    letter-spacing: 1px !important;
    font-family: "Rubik", Arial, sans-serif;
}

.row-gap {
    row-gap: 12px !important;
}

.tacticsboard_others_icons {
    width: 100%;
    transform: scale(0.5);
    display: flex;
    cursor: pointer;
    overflow: hidden;
    padding: 4px 6px;
    align-items: center;
    justify-content: center;
    user-select: none;
    background: #EEECE9;
    border-radius: 10px;
}

.ml-15 {
    margin-left: 15px !important;
}

.icon-element {
    height: 3.1rem !important;
}

.free_shape {
    display: none;
    flex-direction: column;
    margin-left: 15px;
}

.tacticsboard__formation {
    height: 116px;
    width: 116px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;
}

.side-toggle-button-container {
    .button {
        display: inline-block;
        padding: 4px;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s, border-color 0.3s, color 0.3s;
        background-color: #393836;
        font-size:8px !important;
        color: white;
        width:40px
    }

    .button.selected {
        background-color: #0464b3;
        border-color: #0464b3;
    }

    .icon {
        padding-top: 7px;
        padding-bottom: 7px;
    }
}


@media only screen and (max-width: 992px) {
    .player-custom-image {
        >img {
            cursor: pointer;
            object-fit: contain;
            border-radius: 28px;
            box-shadow: 3px 6px 5px 2px rgba(0, 0, 0, 0.25), inset 2px 2px 3px rgba(0, 0, 0, 0.25) !important;
        }
    }
    .tacticsboard__feature_container {
        display: flex !important;
        flex-direction: row !important;
        overflow-x: auto;
    }
    .tacticsboard__mobile_container{
        margin-top: 9px;
    }
    .tacticsboard__mobile_container::-webkit-scrollbar {
        display: none; /* Hide scrollbar for Webkit/Blink browsers */
    }
    .player-custom-image::-webkit-scrollbar {
        display: none !important; /* Hide scrollbar for Webkit/Blink browsers */
    }

    .tacticsboard__mobile_container {
        scrollbar-width: none; /* Hide scrollbar for Firefox */
    }
    .player-custom-image {
        scrollbar-width: none !important; /* Hide scrollbar for Firefox */
    }
    .player-custom-image {
        border-radius: 8px;
        margin-top: 4px;
        overflow-y: hidden;
        overflow-x: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .sub_menu_container{
        flex: 0 0 156px !important;
        display: flex;
        flex-direction: column;
        background: black;
        padding: 5px 10px;
    }
    .expandable_bottom_container{
        flex: 0 0 50px;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        position: absolute;
        bottom: 10px;
        width: 85%;
        background: #000;
        .expandable_sizes{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
        }
    }

    .expandable_sizes button{
        background: transparent !important;
        height: 25px;
        width: 25px;
    }
    .expandable_buttons{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 32px;;
        background: #FFFFFF;
        border-radius: 6px;
        margin-bottom: 4px;
    }
    .expandable_bottom_container span{
        user-select: none;
        font-family: "Rubik", Arial, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
    }
    .size_container{
        display: flex;
        flex-direction: column;
    }
    .size_container .size_title{
        color: #FFFFFF;
        padding: 5px;
    }
    .selected_color{
        height: 17px;
        width: 17px;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        border-radius: 10px;
        cursor: pointer;
    }
    .rotation{
        height: 17px;
        width: 17px;
        border-radius: 10px;
        cursor: pointer;
    }
    .tacticsboard__formation_f3:hover{
        .tacticsboard__formation_hoverd{
            display: flex;
        }
    }
    .mob-formation-container{
        display: flex;
        overflow-x: auto;
    }
    .tacticsboard__formation_container{
        overflow-y: hidden !important;
        display: flex;
        height: calc(100vh - 100px);
        display: flex !important;
        justify-content: space-between;
        overflow-x: auto !important;
        .tacticsboard__formation_f3{
            width: 45px !important;
            height: 45px !important;
            margin-top: 0px;
            margin-right: 30px;
            position: relative;
        }
        .formation-element{
            width: 45px !important;
            height: 45px !important;
        }
        .tacticsboard__formation_hoverd{
            display: none;
            position: absolute;
            left: 0px;
            top: -3px;
            opacity: 0.9;
            height: 50px;
            width: 50px;
            background: #5A5FE7;
            border: 1px solid #5A5FE7;
            border-radius: 6px;
        }
    }
    .tacticsboard__feature_container_height {
        max-height: calc(100vh - 120px);
    }
    .tacticsboard__tools_container{
        .tools_title{
            font-size: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            word-break: break-all;
            width: 100%;
        }
    }
    .freeDrawMargin {
        margin-left: -30px !important;
    }

    .svg__number_icon {
        >img {
            height: 2.5rem !important;
            width: 2.5rem !important;
        }
    }
    .tacticsboard__modal_wrap {
        .player-element {
            overflow: unset !important;

            >img {
                box-shadow: 0px 0px 0px 0px !important;
            }
        }

        .svg__number_icon-small {
            > img {
                height: 10px !important;
                width: 10px !important;
                border-radius: 28px;
            }
        }

        .svg__number_icon-medium {
            > img {
                height: 20px !important;
                width: 20px !important;
                border-radius: 28px;
            }
        }

        .svg__number_icon-large {
            > img {
                height: 30px !important;
                width: 30px !important;
                border-radius: 28px;
            }
        }
    }
    .size-buttons{
        display: flex !important;
        flex-direction: column !important;
    }
    .tools_container_style {
        .tacticsboard__tools__tabs {
            margin-top: 0px !important;
        }
        display: flex;
        padding: 0px 0px 1px !important;
    }

    .tools_container_style {
        .tacticsboard__feature_icon img {
            object-fit: revert !important;
        }
    }
    .tacticsboard__tools_wrapper{
        overflow-y: auto;
        overflow-x: hidden;
        flex: 0 0 60px!important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
    }
    .tools_element{
        padding: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }
    .tacticsBoard_mainTool_icon{
        display: flex !important;
        align-items: center;
        height: 18px !important;
        width: 18px !important;
    }
    .add_playerButton{
        background: #5A5FE7;
        color: #FFFFFF;
    }
    .back_to_player{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-top: 8px;
    }
    .selectedPanel{
        .tools_title{
            color: #5A5FE7 !important;
        }
        background: #F5F6F7;
    }

    .tacticsboard__feature_icon {
        height: 47px;
        width: 47px;
    }
    .tacticsboard__feature_icon-p {
        height: 32px;
        width: 32px;
    }
    .tacticsboard__feature_icon-sq {
        height: 38px;
        width: 42px;
    }
    .tacticsboard__feature_icon-sprite {
        height: 38px;
        width: 42px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .size_container .size_title{
        display: none;
    }

    .btn-wrp-gap{
        gap:5px;
    }
}
.prim{
    color: #ffffff !important;
    border-color: #0464b3 !important;
    background-color: #0464b3 !important;
}

.size_container{
    display: flex;
    flex-direction: column;
}
.switch_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span{
        color: #FFFFFF;
    }
    .size_title{
        padding: 5px;
    }
    .toggle_number_switch{
        border-radius: 4px;
        span{
            font-size:10px !important;
            margin-top: -2px !important;
        }
    }
}

.player_type_title{
    display:none;
}

@media(max-width: 768px){
    .tools_element{
        padding: 2px !important;
    }
}
@media(max-width: 992px){
    .expandable_buttons{
        display: flex;
        flex-direction: column !important;
        align-items: center;
        justify-content: center;
        padding: 0px !important;
        cursor: pointer;
        gap:0 !important;
        height: 40px !important;
        background: #FFFFFF;
        border-radius: 6px;
        margin-bottom: 4px;
    }
    .line-btn{
        width: 44px !important; ;
        height: 44px !important;
        border-radius: 10px !important;
        background: transparent !important;

    }

    .switch_container {
        gap:5px;
        .size_title {
            color: #000000;
        }
    }
}

// responsive by Khalid Abbas
@media only screen and (min-width: 992px) and (max-width: 1290px) {
    .tacticsboard__tools_container {
        .ant-tabs-nav-list {
            width: 100%;
        }
        .ant-tabs-tab {
            width: 15px;
        }
    }
}

.tacticsboard__tools_players_adder {
    text-align: center;
}

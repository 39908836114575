.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pleaseRotate{
  height: 100vh;
  width: 100%;
  background-color: #000c17;
  z-index: 99999999;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -52%);
}
.pleaseRotate-inner{
  transform: translate(0%, 250%);
}
.phone {
  margin:auto;
  height: 50px;
  width: 100px;
  border: 3px solid white;
  border-radius: 10px;
  animation: rotate 1.5s ease-in-out infinite alternate;
}

.message {
  color: white;
  font-size: 1em;
  margin-top: 40px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg)
  }
  50% {
    transform: rotate(-90deg)
  }
  100% {
    transform: rotate(-90deg)
  }
}

@media only screen and (max-width: 475px) and (orientation: portrait) {
  .pleaseRotate {
    display: block;
  }
  .tactictics__board_drawer{
    z-index: -1 !important;
  }
}
@media only screen and (orientation: landscape) {
  .pleaseRotate {
    display: none;
  }
  .tactictics__board_drawer{
    z-index: 1000;
  }
}
